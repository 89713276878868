<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-if="dialogImport" v-model='dialogImport' max-width="600px")
      v-card
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title Importar Combo
            v-btn(icon="" dark="" @click="dialogImport = false" style="position: absolute; top: 0px; right: 10px;")
              v-icon.primary.rounded mdi-close
        v-card-text(style='padding-bottom: 0;')
          div(style="widht: 100% text-align: center;")
            a(class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2Fformato-importar-combos.xlsx?alt=media&token=08048c78-7b6d-47c3-b8fe-848d80a435da") Descargar Plantilla para Carga de Combos
            p
              strong(style="font-weight: bold;") CÓDIGOS DE IMPLEMENTACIÓN:
            v-row
              v-col(cols="12" md="6")
                p
                  strong(style="font-weight: bold;") Tarifas del IVA
                p(style="margin-bottom: 0px;") IVA Cero -> 0
                p(style="margin-bottom: 0px;") IVA 12% -> 2
                p(style="margin-bottom: 0px;") IVA 14% -> 3
                p(style="margin-bottom: 0px;") IVA 15% -> 4
                p(style="margin-bottom: 0px;") IVA 5% -> 5
                p(style="margin-bottom: 0px;") No Objeto de Impuesto -> 6
                p(style="margin-bottom: 0px;") Exento de IVA -> 7
                p(style="margin-bottom: 0px;") IVA diferenciado -> 8
                p(style="margin-bottom: 0px;") IVA 13% -> 10
              // v-col(cols="12" md="6")
                p
                  strong(style="font-weight: bold;") Tipo de Combo
                p(style="margin-bottom: 0px;") Bien -> 01
                p(style="margin-bottom: 0px;") Servicio -> 02
            input#inputExcel(type='file' style="padding-top: 10px; font-family: 'ceraProMedium', sans-serif !important;")
          v-row(style='align-items: center;')
            v-col(cols='12')
              p(style="margin-bottom: 0px;") Para asistencia contáctanos:
                v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                  v-icon(color="black") mdi-whatsapp
            //v-col(cols='6' style='text-align:end;')
              v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
                v-icon(color="black") mdi-facebook
              v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
                v-icon(color="black") mdi-instagram
        v-card-actions
          v-spacer
          v-btn.success-btn(text='' @click='dialogImport = false' :loading='loadingSaveProduct')
            | Finalizar
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialodTransaction')
      v-card
        transaction-combo-data-modal(@closeModal="closeTransactionModal" :productToTransaction='productToTransaction' @productToInventory='productToInventory = $event')
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        menu-combo-modal(@closeModal="closeInventoryModal" :productToEdit='productToEdit' :forEditProduct='forEditProduct')
    base-material-card.px-5.py-3(icon='mdi-food' title='Lista de Combos' style='min-height: 100vh;')
      v-container#inventory-table(fluid='' tag='section')
        v-row
          v-col(cols="12" md="6")
            v-divider.mx-4(inset="" vertical="")
            //- *************************** Buscador ***************************
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="2")
            v-btn.mr-0(color='primary' rounded='' @click='addNewProduct' style="width: 100%;")
              strong
                | NUEVO
                br
                | COMBO
          v-col(cols="12" md="2")
            v-btn.mr-0(color='green' rounded='' @click='importProducts' style="width: 100%;")
              strong
                | IMPORTAR
                br
                | COMBOS
          v-col(cols="12" md="2")
            v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFile' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | LISTA
      v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
      v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="comboLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Combos por página'\}")
        template(v-slot:[`item.price`]='{ item }') $ {{ parseFloat(item.activeValue).toFixed(2) }}
        template(v-slot:[`item.total_value`]='{ item }') $ {{  parseFloat(item.totalValue).toFixed(2) }}
        template(v-slot:[`item.quantityView`]='{ item }') {{ item.type.codigo.toString() === '02' ? 1 : item.quantity }}
        template(v-slot:item.edit="{ item }")
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-btn(text="" icon="" color='blue' v-on="on" @click="editProduct(item)")
                v-icon mdi-lead-pencil
            span.tooltips Editar Combo
        template(v-slot:item.transaction="{ item }")
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-btn(text="" icon="" color='green' v-on="on" @click="transactionProduct(item)")
                v-icon mdi-transfer
            span.tooltips Ajustar inventario
        //- template(v-slot:item.delete="{ item }")
        //-   v-tooltip(bottom="")
        //-     template(v-slot:activator="{ on }")
        //-       v-btn(text="" icon="" color='red' v-on="on" @click="deleteProduct(item)")
        //-         v-icon(small) mdi-trash-can-outline
        //-     span.tooltips Borrar Combo
        //- Mensaje de alerta cuando no hay resultados
        v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
        v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
          | Sin datos
        v-flex(xs12='', sm10='', offset-sm1='')
</template>

<script>
  import readXlsxFile from 'read-excel-file'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  import * as vars from '../../../vars.json'
  export default {
    components: {
      MenuComboModal: () => import('@/views/dashboard/component/application/MenuComboModal'),
      TransactionComboDataModal: () => import('@/views/dashboard/component/application/TransactionComboDataModal'),
    },
    data: () => ({
      newTarifaIva: '',
      loadingSaveProduct: false,
      dialogImport: false,
      forEditProduct: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      productToInventory: {},
      productToTransaction: {},
      productToEdit: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '02',
          texto: 'SERVICIO',
        },
        quantity: '1',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
        combo: true,
      },
      headers: [
        { text: 'Cod. Combo', value: 'codArticle', align: 'left', filterable: true },
        { text: 'Nombre', value: 'name', align: 'left', filterable: true },
        // { text: 'Descripción', value: 'description', align: 'left', filterable: true },
        // { text: 'Tipo', value: 'type.texto', align: 'left', filterable: true },
        // { text: 'Cantidad', value: 'quantityView', align: 'left', filterable: true },
        { text: 'Costo sin IVA', value: 'price', align: 'left', filterable: true },
        { text: 'Valor Total', value: 'activeWValue', align: 'left', filterable: true },
        { text: 'Editar', align: 'center', value: 'edit', sortable: false }, // No se reordena
        { text: 'Transacciones', align: 'center', value: 'transaction', sortable: false }, // No se reordena
        // { text: 'Borrar', align: 'center', value: 'delete', sortable: false }, // No se reordena
      ],
      ordenarPor: 'codArticle',
      search: '',
      cargando: false,
      firstLoad: false,
      dialodInvMod: false,
      dialodTransaction: false,
    }),
    computed: {
      comboLst () {
        return this.$store.state.product.lstCombos
      },
    },
    watch: {
      async productToInventory () {
        await this.$store.dispatch('product/setCombo', [this.productToInventory.productKey, this.productToInventory]).then(async resolve => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Combo actualizado correctamente',
          }
          this.$emit('closeModal')
        }, reject => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar el combo',
          }
        })
      },
    },
    mounted () {
      this.newTarifaIva = vars.validIva
      this.getLstInec()
    },
    methods: {
      async getLstInec () {
        if (!this.$store.state.facturacion.tokenAdmin) {
          await this.$store.dispatch('facturacion/adminLoginFac')
        }
        const data = {
          token: this.$store.state.facturacion.tokenAdmin,
          filterData: '',
        }
        this.$store.dispatch('facturacion/getInecCodes', data)
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      importProducts () {
        this.dialogImport = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcel')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveProduct = true
              const comboLst = []
              let globalBan = true
              for (let i = 1; i < rows.length; i++) {
                let ban = true
                let uidVal = ''
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el campo indicado para continuar.'
                if (!rows[i][0] || rows[i][0].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Código')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][1] || rows[i][1].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Nombre')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][3] || rows[i][3].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Costo Unitario sin IVA')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                const val = rows[i][5].toString()
                if (!val || val.trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tarifa del IVA')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                let txtTypeCombo = ''
                if (ban) {
                  rows[i][4] = '1'
                  txtTypeCombo = 'SERVICIO'
                }

                const productValid = this.comboLst.filter(
                  item => {
                    const codeProduct = rows[i][0] ? rows[i][0] : ''
                    return (item.codArticle === codeProduct)
                  })
                if (productValid.length > 0) {
                  uidVal = productValid[0].id
                }

                if (ban) {
                  let textoSend = ''
                  let codigoSend = ''
                  let valorSend = ''
                  switch (rows[i][5].toString()) {
                    case '0':
                      textoSend = '0%'
                      codigoSend = '0'
                      valorSend = '0'
                      break
                    case '2':
                      textoSend = '12%'
                      codigoSend = '2'
                      valorSend = '12'
                      break
                    case '3':
                      textoSend = '14%'
                      codigoSend = '3'
                      valorSend = '14'
                      break
                    case '4':
                      textoSend = '15%'
                      codigoSend = '4'
                      valorSend = '15'
                      break
                    case '5':
                      textoSend = '5%'
                      codigoSend = '5'
                      valorSend = '5'
                      break
                    case '6':
                      textoSend = 'No Objeto de Impuesto'
                      codigoSend = '6'
                      valorSend = '0'
                      break
                    case '8':
                      textoSend = 'IVA diferenciado'
                      codigoSend = '8'
                      valorSend = '0'
                      break
                    case '10':
                      textoSend = '13%'
                      codigoSend = '10'
                      valorSend = '13'
                      break
                    default:
                      textoSend = 'Exento de IVA'
                      codigoSend = '7'
                      valorSend = '0'
                  }

                  const productSend = {
                    codArticle: rows[i][0] ? rows[i][0] : '',
                    barcode: '',
                    name: rows[i][1] ? rows[i][1] : '',
                    description: rows[i][2] ? rows[i][2] : '',
                    type: {
                      codigo: '02',
                      texto: txtTypeCombo,
                    },
                    quantity: rows[i][4] ? rows[i][4] : '',
                    activeValue: rows[i][3] ? rows[i][3] : '',
                    productKey: '',
                    completeData: '',
                    tarifaiva: {
                      codigo: codigoSend,
                      texto: textoSend,
                      valor: valorSend,
                    },
                    irbpnr: '',
                    tarifaice: '',
                    totalValue: '',
                    validChangeIva: true,
                  }
                  if (uidVal !== '') {
                    productSend.productKey = uidVal
                    comboLst.push(productSend)
                  } else {
                    comboLst.push(productSend)
                  }
                } else {
                  globalBan = false
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
              }
              if (globalBan) {
                let banEnd = true
                let correctRegister = 0
                let errorRegister = 0
                if (comboLst.length > 0) {
                  for (let i = 0; i < comboLst.length; i++) {
                    if (!comboLst[i].productKey || comboLst[i].productKey === '') {
                      comboLst[i].productKey = firebase.database().ref(`clients/${this.currentUserId}`).push().getKey()
                    }
                    comboLst[i].activeValue = parseFloat(comboLst[i].activeValue.toString().replace(',', '.'))
                    comboLst[i].totalValue = parseFloat(parseFloat(comboLst[i].quantity) * parseFloat(comboLst[i].activeValue)).toFixed(2)
                    comboLst[i].completeData = comboLst[i].codArticle + ' | ' + comboLst[i].name + ' | ' + comboLst[i].description + ' | ' + comboLst[i].barcode
                    await this.$store.dispatch('product/setCombo', [comboLst[i].productKey, comboLst[i]]).then(resolve => {
                      correctRegister++
                    }, reject => {
                      banEnd = false
                      errorRegister++
                    })
                  }
                  if (banEnd) {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  } else {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  }
                } else {
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'No se tiene registros para agregar.',
                  }
                }
              }
            }).catch((e) => {
              this.dialogImport = false
              this.loadingSaveProduct = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      transactionProduct (itemTransaction) {
        this.dialodTransaction = true
        this.productToTransaction = itemTransaction
      },
      editProduct (itemToEdit) {
        this.forEditProduct = true
        this.dialodInvMod = true
        this.productToEdit = itemToEdit
      },
      // async deleteProduct (productToDelete) {
      //   await this.$store.dispatch('product/deleteProduct', productToDelete.productKey).then(() => {
      //     this.snackbar = {
      //       show: true,
      //       color: 'green',
      //       text: 'Combo eliminado correctamente',
      //     }
      //   }).catch(() => {
      //     this.snackbar = {
      //       show: true,
      //       color: 'red',
      //       text: 'Error al eliminar el producto',
      //     }
      //   })
      // },
      addNewProduct () {
        this.forEditProduct = false
        this.dialodInvMod = true
        this.productToEdit = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '02',
            texto: 'SERVICIO',
          },
          quantity: '1',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '2',
            texto: '12%',
            valor: '12',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
          inecCode: null,
          combo: true,
        }
      },
      async closeInventoryModal () {
        this.dialodInvMod = false
      },
      async closeTransactionModal () {
        this.dialodTransaction = false
      },
      downLoadExcelFile () {
        const data = []
        if (this.comboLst) {
          for (let i = 0; i < this.comboLst.length; i++) {
            data.push({
              'No. de articulo': this.comboLst[i].codArticle,
              Nombre: this.comboLst[i].name,
              Descripcion: this.comboLst[i].description,
              Tipo: this.comboLst[i].type,
              Cantidad: this.comboLst[i].quantity,
              'Valor de activo': this.comboLst[i].activeValue,
              'Valor total': this.comboLst[i].totalValue.replace('.', ','),
              Modelo: this.comboLst[i].model,
              Observaciones: this.comboLst[i].description,
            })
          }
          functions.JSONToCSVConvertor(data, 'Combos', true)
        }
      },
    },
  }
</script>
